<script>
/* COMPOSANTS */
import PrestationBadge from '@components/general/PrestationBadge';
import PrestationStatusBadge from '@components/general/PrestationStatusBadge';
import MissionDetails from './MissionDetails';

export default {
  components: {
    PrestationBadge,
    PrestationStatusBadge,
    MissionDetails,
  },
  props: {
    modelValue: {
      required: true,
    },
    editable: {
      required: true,
    },
  },
  data() {
    return {
      list: [],
      activeTab: 0,
    };
  },
  watch: {},
  mounted() {
    this.initList();
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    initList() {
      if (this.currentValue) {
        this.list = [
          {
            dateStart: this.currentValue?.dateStart,
            dateCreated: this.currentValue?.dateEnd,
            type: this.currentValue?.type ?? '',
          },
        ];
      }
    },
  },
};
</script>

<template>
  <div class="mission-order-details">
    <div class="section">
      <DataTable
        :value="list"
        class="table gd-datatable"
        current-page-report-template="{first}-{last}/{totalRecords}"
        responsive-layout="scroll"
        striped-rows>
        <Column header="Date ordre">
          <template #body="slotProps">
            {{ new Date(slotProps.data.dateCreated).toLocaleDateString() }}
          </template>
        </Column>
        <Column header="Jour">
          <template #body="slotProps">
            {{ new Date(slotProps.data.dateStart).toLocaleDateString() }}
          </template>
        </Column>
        <Column header="Heure">
          <template #body="slotProps">
            {{
              (new Date(slotProps.data.dateStart).getHours() >= 10
                ? new Date(slotProps.data.dateStart).getHours()
                : '0' + new Date(slotProps.data.dateStart).getHours()) +
              ':' +
              (new Date(slotProps.data.dateStart).getMinutes() >= 10
                ? new Date(slotProps.data.dateStart).getMinutes()
                : '0' + new Date(slotProps.data.dateStart).getMinutes())
            }}
          </template>
        </Column>
        <Column header="Type">
          <template #body="slotProps">
            <PrestationBadge :type="slotProps.data.type" />
          </template>
        </Column>
        <Column header="Statut des intervenants">
          <template #body>
            <PrestationStatusBadge :status="this.currentValue.statusParticipants" />
          </template>
        </Column>
        <Column header="Statut de la mission">
          <template #body>
            <PrestationStatusBadge :status="this.currentValue.statusPrestation" />
          </template>
        </Column>
        <Column header="Nb d'intervenants">
          <template #body>
            {{ this.currentValue.participantNumber }}
          </template>
        </Column>
      </DataTable>
      <h5>Informations de la mission</h5>
      <MissionDetails v-if="activeTab === 0" :modelValue="this.currentValue" :editable="editable" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mission-order-details {
  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 16px;
    margin-bottom: 24px;
    gap: 16px;
  }
}
</style>
