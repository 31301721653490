<script>
/* MIXINS */
import missionMixin from '@mixins/missionMixin';

export default {
  mixins: [missionMixin],
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    statusClass() {
      if (this.status) {
        return this.status.toLowerCase().replace('_', '-');
      } else {
        return 'anomaly';
      }
    },
  },
  methods: {},
};
</script>

<template>
  <div class="prestation-status-badge" :class="`--${statusClass}`">
    <div class="badge-icon">
      {{ statusDisplay(status?.replace(' ', '')) }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.prestation-status-badge {
  padding: 5px 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid;
  border-radius: 32px;
  width: fit-content;

  > span {
    font: 500 12px/18px 'Poppins';
  }

  &.--proposed,
  &.--partially-accepted,
  &.--to-plan {
    background: #fffaeb;
    color: #b54708;
  }

  &.--accepted,
  &.--todo,
  &.--ongoing,
  &.--doing {
    background: #ecfdf3;
    color: #027a48;
  }

  &.--canceled {
    background: #fef3f2;
    color: #b42318;
  }

  &.--completed {
    background: #f2f4f7;
    color: #344054;
  }

  &.--litigation,
  &.--anomaly {
    background: #fef3f2;
    color: #b42318;
  }
}
</style>
