export default {
  data() {
    return {
      personnelTypeConst: [
        {label: this.$t('assigned_staff.prestataire'), value: 'PRESTATAIRE'},
        {label: this.$t('assigned_staff.intern'), value: 'INTERN'},
        {label: this.$t('assigned_staff.housekeeper'), value: 'HOUSEKEEPER'},
      ],
      personnelTypeFilterConst: [
        {label: this.$t('planification.prestataire'), value: 'PRESTATAIRE'},
        {label: this.$t('planification.intern'), value: 'INTERN'},
        {label: this.$t('planification.housekeeper'), value: 'HOUSEKEEPER'},
      ],
    };
  },
  methods: {
    displayForPersonnelTypeConst(value) {
      let result = this.personnelTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    displayForPersonnelTypeFilterConst(value) {
      let result = this.personnelTypeFilterConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
