import store from '@store';
import {PermissionMap} from '@mixins/const/permissionConst';

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@views/Login.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['loggedIn']) {
          // Redirect to the home page instead
          next({name: 'home'});
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/resetPwd',
    name: 'resetPwd',
    component: () => import('@views/ResetPwd.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/forgotPwd/:jwt',
    name: 'forgotPwd',
    component: () => import('@views/ForgotPwd.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/',
    component: () => import('@layouts/Main.vue'),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@views/Home.vue'),
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@views/user/Profile.vue'),
        meta: {
          authRequired: true,
          //accessTo: ["GAV", "GAS", "OWNER"],
          restrictedTo: ['ADMIN'],
        },
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@views/user/UserList.vue'),
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'user/create',
        name: 'userCreate',
        component: () => import('@views/user/UserCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'users',
        },
      },
      {
        path: 'user/:userId/update',
        name: 'userUpdate',
        component: () => import('@views/user/UserUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'users',
        },
      },
      {
        path: 'companies',
        name: 'companies',
        component: () => import('@views/company/CompanyList.vue'),
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'company/create',
        name: 'companyCreate',
        component: () => import('@views/company/CompanyCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'companys',
        },
      },
      {
        path: 'company/:companyId/update',
        name: 'companyUpdate',
        component: () => import('@views/company/CompanyUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'companys',
        },
      },
      {
        path: 'company/:companyId',
        name: 'companyPage',
        component: () => import('@views/company/CompanyPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'companys',
        },
      },
      {
        path: 'company/:companyId/accommodationtypes/create',
        name: 'companyAccommodationTypeCreate',
        component: () => import('@views/company/accommodationtype/CompanyAccommodationTypeCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'companys',
        },
      },
      {
        path: 'prospects',
        name: 'prospects',
        component: require('@views/prospect/ProspectList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'prospects',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.PROSPECT.VIEW],
        },
      },
      {
        path: 'prospect/create',
        name: 'prospectCreate',
        component: () => import('@views/prospect/ProspectCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'prospects',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.PROSPECT.MANAGE],
        },
      },
      {
        path: 'prospect/:ownerId',
        name: 'prospectPage',
        component: () => import('@views/prospect/ProspectPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'prospects',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.PROSPECT.VIEW],
        },
      },
      {
        path: 'prospect/:ownerId/update',
        name: 'prospectUpdate',
        component: () => import('@views/prospect/ProspectUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'prospects',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.PROSPECT.MANAGE],
        },
      },
      {
        path: 'owner/:ownerId',
        name: 'ownerPage',
        component: () => import('@views/owner/OwnerPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'owners',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.OWNER.VIEW],
        },
      },
      {
        path: 'owner/:ownerId/update',
        name: 'ownerUpdate',
        component: () => import('@views/owner/OwnerUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'owners',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.OWNER.MANAGE],
        },
      },
      {
        path: 'owners',
        name: 'owners',
        component: require('@views/owner/OwnerList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'owners',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.OWNER.VIEW],
        },
      },
      {
        path: 'clients',
        name: 'clients',
        component: require('@views/client/ClientList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'clients',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.CLIENT.VIEW],
        },
      },
      {
        path: 'client/create',
        name: 'clientCreate',
        component: () => import('@views/client/ClientCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'clients',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.CLIENT.MANAGE],
        },
      },
      {
        path: 'client/:clientId/update',
        name: 'clientUpdate',
        component: () => import('@views/client/ClientUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'clients',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.CLIENT.MANAGE],
        },
      },
      {
        path: 'client/:clientId',
        name: 'clientPage',
        component: () => import('@views/client/ClientPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'clients',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.CLIENT.VIEW],
        },
      },
      {
        path: 'client/:clientId/reservation/create',
        name: 'clientReservationCreate',
        component: () => import('@views/client/reservations/ClientReservationCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'clients',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.CLIENT.MANAGE],
        },
      },
      {
        path: 'client/:clientId/reservation/update/:reservationId',
        name: 'clientReservationUpdate',
        component: () => import('@views/client/reservations/ClientReservationUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'clients',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.CLIENT.MANAGE],
        },
      },
      {
        path: 'client/:clientId/reservation/details/:reservationId',
        name: 'clientReservationDetails',
        component: () => import('@views/client/reservations/ClientReservationDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'clients',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.CLIENT.VIEW],
        },
      },
      {
        path: 'mandats',
        name: 'mandatPage',
        component: () => import('@views/mandat/MandatPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
          subMenu: 'mandats',
          permissions: [PermissionMap.MANDAT.VIEW],
        },
      },
      {
        path: 'avenant/:avenantId/sign',
        name: 'AvenantSign',
        component: require('@views/mandat/avenant/AvenantSign.vue').default,
        meta: {
          authRequired: true,
          accessTo: ['OWNER'],
        },
      },
      {
        path: 'suivis',
        name: 'suivis',
        component: () => import('@views/onboarding/OnboardingSuivi.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'suivis',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'suivis/:onboardingId',
        name: 'suivisWithId',
        component: () => import('@views/onboarding/OnboardingSuivi.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'suivis',
          accessTo: ['GAS'],
        },
      },
      {
        path: 'employee',
        name: 'employee',
        component: require('@views/employee/EmployeeList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'employee',
          //accessTo: ['GAS', 'GAV', 'ADMIN'],
          permissions: [PermissionMap.RH.VIEW],
        },
      },
      {
        path: 'employee/:employeeId',
        name: 'employeePage',
        component: require('@views/employee/EmployeePage.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'employee',
          //accessTo: ['GAV'],
          permissions: [PermissionMap.RH.VIEW],
        },
      },
      {
        path: 'employee/:employeeId/update',
        name: 'employeeUpdate',
        component: () => import('@views/employee/EmployeeUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'employee',
          //accessTo: ['GAS', 'GAV', 'ADMIN'],
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/contract/create',
        name: 'employeeContractCreate',
        component: () => import('@views/employee/contract/EmployeeContractCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/contract/:employeeContractId/update',
        name: 'employeeContractUpdate',
        component: () => import('@views/employee/contract/EmployeeContractUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/contract/:employeeContractId/details',
        name: 'employeeContractDetails',
        component: () => import('@views/employee/contract/EmployeeContractDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.VIEW],
        },
      },
      {
        path: 'employee/:employeeId/contract/:employeeContractId/amendment',
        name: 'employeeAmendmentCreate',
        component: () => import('@views/employee/amendment/EmployeeAmendmentCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/restriction/create',
        name: 'restrictionCreate',
        component: () => import('@views/employee/restriction/EmployeeRestrictionCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/restriction/:restrictionId/update',
        name: 'restrictionUpdate',
        component: () => import('@views/employee/restriction/EmployeeRestrictionUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/restriction/:restrictionId/details',
        name: 'restrictionDetails',
        component: () => import('@views/employee/restriction/EmployeeRestrictionDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.VIEW],
        },
      },
      {
        path: 'employee/:employeeId/epi/create',
        name: 'epiCreate',
        component: () => import('@views/employee/epi/EmployeeEpiCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/epi/:epiId/update',
        name: 'epiUpdate',
        component: () => import('@views/employee/epi/EmployeeEpiUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/absence/create',
        name: 'absenceCreate',
        component: () => import('@views/employee/absence/EmployeeAbsenceCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/absence/:absenceId/update',
        name: 'absenceUpdate',
        component: () => import('@views/employee/absence/EmployeeAbsenceUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
        },
      },
      {
        path: 'employee/:employeeId/absence/:absenceId/details',
        name: 'absenceDetails',
        component: () => import('@views/employee/absence/EmployeeAbsenceDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.VIEW],
        },
      },
      {
        path: 'employee/:employeeId/unavailability/create',
        name: 'unavailabilityCreate',
        component: () => import('@views/employee/unavailability/EmployeeUnavailabilityCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'employee/:employeeId/unavailability/:unavailabilityId/update',
        name: 'unavailabilityUpdate',
        component: () => import('@views/employee/unavailability/EmployeeUnavailabilityUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'employee',
          permissions: [PermissionMap.RH.MANAGE],
        },
      },
      {
        path: 'reservations',
        name: 'reservations',
        component: require('@views/reservation/ReservationsList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'reservation',
          accessTo: ['GAS', 'GAV', 'OWNER', 'PARTENAIRE', 'HOUSEKEEPER'],
          //permissions: [PermissionMap.RESERVATION.VIEW]
        },
      },
      {
        path: 'reservations/planning',
        name: 'reservationsPlanning',
        component: require('@views/reservation/ReservationsPlanning.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'reservation',
          accessTo: ['GAS', 'GAV', 'OWNER', 'PARTENAIRE', 'HOUSEKEEPER'],
          //permissions: [PermissionMap.RESERVATION_PLANNING.VIEW]
        },
      },
      {
        path: 'reservations/payment',
        name: 'reservationsPayment',
        component: require('@views/reservation/Payment.vue').default,
        meta: {
          menuSelected: 'reservation',
          permissions: [PermissionMap.RESERVATION_REGLEMENT.VIEW],
        },
      },
      {
        path: 'reservations/defautpaiement',
        name: 'defautpaiement',
        component: require('@views/reservation/DefautPaiement.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'reservation',
          permissions: [PermissionMap.RESERVATION_REGLEMENT.VIEW],
        },
      },
      {
        path: 'reservations/cancel',
        name: 'reservationsCancel',
        component: require('@views/reservation/Cancel.vue').default,
        meta: {
          menuSelected: 'reservation',
          permissions: [PermissionMap.RESERVATION_REGLEMENT.VIEW],
        },
      },
      {
        path: 'reservations/paymentdetails/:reservationId',
        name: 'reservationsPaymentDetails',
        component: () => import('@views/reservation/PaymentDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
          permissions: [PermissionMap.RESERVATION_REGLEMENT.VIEW],
        },
      },
      {
        path: 'reservations/canceldetails/:reservationId',
        name: 'cancelDetails',
        component: () => import('@views/reservation/CancelDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
          permissions: [PermissionMap.RESERVATION_REGLEMENT.VIEW],
        },
      },
      {
        path: 'maintenances',
        name: 'maintenances',
        component: require('@views/maintenance/MaintenanceList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          accessTo: ['GAS', 'GAV', 'PARTENAIRE', 'HOUSEKEEPER', 'OWNER'],
        },
      },
      {
        path: 'maintenance/:maintenanceId/details',
        name: 'maintenanceDetails',
        component: () => import('@views/maintenance/MaintenanceDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'maintenances',
          accessTo: ['GAS', 'GAV', 'PARTENAIRE', 'HOUSEKEEPER', 'OWNER'],
        },
      },
      {
        path: 'maintenance/:maintenanceId/update',
        name: 'maintenanceUpdate',
        component: () => import('@views/maintenance/MaintenanceUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'maintenances',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'upsells',
        name: 'upsells',
        component: require('@views/upsell/UpsellList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'upsells',
          accessTo: ['GAS', 'GAV', 'HOUSEKEEPER'],
        },
      },
      {
        path: 'reviews',
        name: 'reviews',
        component: require('@views/operationnel/review/ReviewList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'reviews',
          accessTo: ['GAS', 'GAV', 'PARTENAIRE', 'HOUSEKEEPER'],
        },
      },
      {
        path: 'accommodations',
        name: 'accommodations',
        component: require('@views/accommodation/AccommodationList.vue').default,
        meta: {
          authRequired: true,
          oldTheme: true,
        },
      },
      {
        path: 'accommodation/:accommodationId',
        name: 'accommodationPage',
        component: () => import('@views/accommodation/AccommodationPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/purchase/create',
        name: 'accommodationPurchaseCreate',
        component: () => import('@views/accommodation/ownerPurchase/AccommodationOwnerPurchaseCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/purchase/details/:ownerPurchaseId',
        name: 'accommodationOwnerPurchaseDetails',
        component: () => import('@views/accommodation/ownerPurchase/AccommodationOwnerPurchaseDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/purchase/update/:ownerPurchaseId',
        name: 'accommodationPurchaseUpdate',
        component: () => import('@views/accommodation/ownerPurchase/AccommodationOwnerPurchaseUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/mandat/:mandatId/details',
        name: 'accommodationMandatDetails',
        component: () => import('@views/accommodation/mandat/AccommodationMandatDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/mandat/:mandatId/resiliation',
        name: 'accommodationMandatResiliation',
        component: () => import('@views/accommodation/mandat/AccommodationMandatResiliation.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/cdc/:cdcId/details',
        name: 'accommodationCdcDetails',
        component: () => import('@views/accommodation/cdc/AccommodationCdcDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/reservation/create',
        name: 'accommodationReservationCreate',
        component: () => import('@views/accommodation/reservation/AccommodationReservationCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/reservation/update/:reservationId',
        name: 'accommodationReservationUpdate',
        component: () => import('@views/accommodation/reservation/AccommodationReservationUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      {
        path: 'accommodation/:accommodationId/reservation/details/:reservationId',
        name: 'accommodationReservationPage',
        component: () => import('@views/accommodation/reservation/AccommodationReservationPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accommodations',
        },
      },
      //            {
      //                path: 'ownerPurchases',
      //                name: 'ownerPurchases',
      //                component: () => import('@views/ownerPurchase/OwnerPurchaseList.vue'),
      //                meta: {
      //                    authRequired: true,
      //                    menuSelected: "operations",
      //                },
      //            },
      //            {
      //                path: 'ownerPurchase/create',
      //                name: 'ownerPurchaseCreate',
      //                component: () => import('@views/ownerPurchase/OwnerPurchaseCreate.vue'),
      //                meta: {
      //                    authRequired: true,
      //                    menuSelected: "operations",
      //                    subMenu: "ownerPurchases",
      //                },
      //            },
      //            {
      //                path: 'ownerPurchase/details/:ownerPurchaseId',
      //                name: 'ownerPurchaseDetails',
      //                component: () => import('@views/ownerPurchase/OwnerPurchaseDetails.vue'),
      //                meta: {
      //                    authRequired: true,
      //                    menuSelected: "operations",
      //                    subMenu: "ownerPurchases",
      //                },
      //            },
      //            {
      //                path: 'ownerPurchase/update/:ownerPurchaseId',
      //                name: 'ownerPurchaseUpdate',
      //                component: () => import('@views/ownerPurchase/OwnerPurchaseUpdate.vue'),
      //                meta: {
      //                    authRequired: true,
      //                    menuSelected: "operations",
      //                    subMenu: "ownerPurchases",
      //                },
      //            },
      {
        path: 'settings/purchaseType',
        name: 'settingsPurchaseType',
        component: require('@views/settings/PurchaseTypeList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/achatHote',
        name: 'settingsAchatHoteType',
        component: require('@views/settings/AchatHoteTypeList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/parametrageCommission',
        name: 'settingsParametrageCommission',
        component: require('@views/settings/ParametrageCommission/ParametrageCommissionPage.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/raisonAchatHote',
        name: 'settingsRaisonAchatHote',
        component: require('@views/settings/RaisonAchatHoteList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/motifDeplacement',
        name: 'settingsMotifDeplacement',
        component: require('@views/settings/MotifDeplacementList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/societeGuestadom',
        name: 'settingsSocieteGuestadom',
        component: require('@views/settings/SocieteGuestadom.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/infosMandat',
        name: 'settingsInfosMandat',
        component: require('@views/settings/InfosMandat.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/extraType',
        name: 'settingsExtraType',
        component: require('@views/settings/ExtraTypeList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/originMarketing',
        name: 'settingsOriginMarketing',
        component: require('@views/settings/OrigineMarketingList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/profileClient',
        name: 'settingsProfileClient',
        component: require('@views/settings/ProfileClientList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },

      {
        path: 'settings/formeJuridique',
        name: 'settingsFormeJuridique',
        component: require('@views/settings/FormeJuridiqueList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/stock',
        name: 'settingsStock',
        component: require('@views/settings/StockList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/zone',
        name: 'settingsZone',
        component: require('@views/settings/ZoneList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/task',
        name: 'settingsTask',
        component: require('@views/task/TaskList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/cdcmodels',
        name: 'settingsCdcmodel',
        component: require('@views/cdcmodel/CdcmodelList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      /**
             {
                path: 'settings/jobs',
                name: 'settingsJobs',
                component: require('@views/settings/JobsList.vue').default,
                meta: {
                    menuSelected: "settings",
                    accessTo: ['ADMIN'],
                },
            },
             **/
      {
        path: 'settings/geosector',
        name: 'settingsGeoSector',
        component: require('@views/geosector/GeoSectorList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/filetype',
        name: 'settingsFileType',
        component: require('@views/settings/FileTypeList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/contract',
        name: 'settingsContract',
        component: require('@views/settings/ContractsList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/epitype',
        name: 'settingsEpiType',
        component: require('@views/settings/EpiTypeList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'accounting/stayrate',
        name: 'accountingStayRate',
        component: require('@views/accounting/stayrates/StayRateList.vue').default,
        meta: {
          menuSelected: 'accounting',
          permissions: [PermissionMap.TDS_GROUP.VIEW],
        },
      },
      {
        path: 'admin/mandat',
        name: 'mandatAdminPage',
        component: require('@views/mandat/MandatAdminPage.vue').default,
        meta: {
          menuSelected: 'mandat',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/documentmodel',
        name: 'settingsDocumentModel',
        component: require('@views/documentmodel/DocumentModelList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/documentmodel/create',
        name: 'settingsDocumentModelCreate',
        component: require('@views/documentmodel/DocumentModelCreate.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/documentmodel/:documentModelId/update',
        name: 'settingsDocumentModelUpdate',
        component: require('@views/documentmodel/DocumentModelUpdate.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/documentmodel/:documentModelId/details',
        name: 'settingsDocumentModelDetails',
        component: require('@views/documentmodel/DocumentModelDetails.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/motifrefus',
        name: 'settingsMotifRefus',
        component: require('@views/motifrefus/MotifRefusList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/pole',
        name: 'settingsPole',
        component: require('@views/pole/PoleList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/sendcrg',
        name: 'sendCrg',
        component: require('@views/settings/SendCRG.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/amountThreshold',
        name: 'amountThreshold',
        component: require('@views/settings/AmountThreshold.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/mandatclusterrm',
        name: 'settingsMandatClusterRm',
        component: require('@views/settings/MandatClusterRMList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/cancelrule',
        name: 'settingsCancelRule',
        component: require('@views/settings/CancelRuleList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/ticketcategory',
        name: 'settingsTicketCategory',
        component: require('@views/settings/TicketCategoryList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'settings/resiliationreason',
        name: 'settingsResiliationReason',
        component: require('@views/settings/ResiliationReasonList.vue').default,
        meta: {
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'task/:taskId/details',
        name: 'taskDetails',
        component: () => import('@views/task/TaskDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          subMenu: 'settingsTask',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'cdcmodel/:cdcmodelId/details',
        name: 'cdcmodelDetails',
        component: () => import('@views/cdcmodel/CdcmodelDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          subMenu: 'settingsCdcmodel',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'taskgroup/:taskgroupId/details',
        name: 'taskgroupDetails',
        component: () => import('@views/taskgroup/TaskgroupDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'stock/:stockId',
        name: 'stockPage',
        component: () => import('@views/stock/StockPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'stock/:stockId/products/create',
        name: 'stockProductCreate',
        component: () => import('@views/stock/product/StockProductCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'stock/:stockId/product/:productId/details',
        name: 'stockProductDetails',
        component: () => import('@views/stock/product/StockProductDetails.vue'),
        meta: {
          authRequired: true,
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'stock/:stockId/product/:productId/update',
        name: 'stockProductUpdate',
        component: () => import('@views/stock/product/StockProductUpdate.vue'),
        meta: {
          authRequired: true,
          accessTo: ['ADMIN'],
        },
      },
      {
        path: 'tickets',
        name: 'tickets',
        component: () => import('@views/ticket/list/TicketList.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'operations',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'ticket/create',
        name: 'ticketCreate',
        component: () => import('@views/ticket/create/TicketCreate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'operations',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.TICKET.MANAGE],
        },
      },
      {
        path: 'ticket/:ticketId/update',
        name: 'ticketUpdate',
        component: () => import('@views/ticket/update/TicketUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'operations',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.TICKET.MANAGE],
        },
      },
      {
        path: 'ticket/:ticketId/details',
        name: 'ticketDetails',
        component: () => import('@views/ticket/details/TicketDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'operations',
          //accessTo: ['GAS', 'GAV'],
          permissions: [PermissionMap.TICKET.VIEW],
        },
      },
      {
        path: 'activity/:activityId/update',
        name: 'activityUpdate',
        component: () => import('@views/activity/ActivityUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'activity/:activityId/details',
        name: 'activityDetails',
        component: () => import('@views/activity/ActivityDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'settings',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'accounting/thirdpartyaccount',
        name: 'accountingThirdPartyAccount',
        component: require('@views/accounting/ThirdPartyAccount.vue').default,
        meta: {
          menuSelected: 'accounting',
          permissions: [PermissionMap.THIRD_ACCOUNT.VIEW],
        },
      },
      {
        path: 'accounting/thirdpartydetails/:accountId',
        name: 'accountingThirdPartyDetails',
        component: () => import('@views/accounting/ThirdPartyDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          permissions: [PermissionMap.THIRD_ACCOUNT.VIEW],
        },
      },
      {
        path: 'accounting/balance',
        name: 'accountingBalance',
        component: require('@views/accounting/Balance.vue').default,
        meta: {
          menuSelected: 'accounting',
          permissions: [PermissionMap.BALANCE.VIEW],
        },
      },
      {
        path: 'accounting/operations',
        name: 'accountingOperations',
        component: require('@views/accounting/operations/OperationsPage.vue').default,
        meta: {
          menuSelected: 'accounting',
          permissions: [PermissionMap.OPERATION.VIEW],
        },
      },
      {
        path: 'accounting/facturationhote',
        name: 'accountingFacturationHote',
        component: require('@views/accounting/facturationhote/FacturationHote.vue').default,
        meta: {
          menuSelected: 'accounting',
          permissions: [PermissionMap.FACTURATION_HOTE.VIEW],
        },
      },
      {
        path: 'accounting/account',
        name: 'accountingAccount',
        component: require('@views/accounting/Account.vue').default,
        meta: {
          menuSelected: 'accounting',
          permissions: [PermissionMap.CONSULTATION.VIEW],
        },
      },
      {
        path: 'accounting/transactions',
        name: 'accountingTransactions',
        component: require('@views/accounting/Transactions.vue').default,
        meta: {
          menuSelected: 'accounting',
          permissions: [PermissionMap.TRANSACTION.VIEW],
        },
      },
      {
        path: 'accounting/transaction/:transactionId',
        name: 'accountingTransactionUpdate',
        component: require('@views/accounting/TransactionUpdate.vue').default,
        meta: {
          menuSelected: 'accounting',
          permissions: [PermissionMap.TRANSACTION.MANAGE],
        },
      },
      {
        path: 'accounting/visitortax',
        name: 'accountingVisitorTax',
        component: require('@views/accounting/stayrates/StayRatePage.vue').default,
        meta: {
          menuSelected: 'accounting',
          permissions: [PermissionMap.TDS.VIEW],
        },
      },
      {
        path: 'accounting/freeod',
        name: 'accountingFreeOd',
        component: require('@views/accounting/FreeOd.vue').default,
        meta: {
          menuSelected: 'accounting',
          permissions: [PermissionMap.OD_LIBRE.MANAGE],
        },
      },
      {
        path: 'accounting/stayratetransfert',
        name: 'accountingStayRateTransfert',
        component: require('@views/accounting/stayrates/tabs/StayRateTransfert.vue').default,
        meta: {
          menuSelected: 'accounting',
          permissions: [PermissionMap.TDS_TRANSFERT.VIEW],
        },
      },
      {
        path: 'accounting/stayratedetails/:reconciliationId/:groupLabel',
        name: 'accountingStayRateDetails',
        component: () => import('@views/accounting/stayrates/StayRateDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          permissions: [PermissionMap.TDS_TRANSFERT.VIEW],
        },
      },
      {
        path: 'crg',
        name: 'crgOwner',
        component: () => import('@views/accounting/crg/CrgOwner.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'crg',
          accessTo: ['OWNER'],
        },
      },
      {
        path: 'accounting/crg',
        name: 'crgPage',
        component: () => import('@views/accounting/crg/CrgPage.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          permissions: [PermissionMap.CRG.VIEW, PermissionMap.CRG_CONSULTATION.VIEW],
        },
      },
      {
        path: 'accounting/crgPayment/:paymentId',
        name: 'crgPayment',
        component: () => import('@views/accounting/crg/CrgPaymentView.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          permissions: [PermissionMap.CRG_TRANSFERT.VIEW],
        },
      },
      {
        path: 'accounting/crg/:crgId',
        name: 'crgView',
        component: () => import('@views/accounting/crg/CrgView.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          permissions: [PermissionMap.CRG_TRANSFERT.VIEW, PermissionMap.CRG_CONSULTATION.VIEW],
        },
      },
      {
        path: 'accounting/crgHistory/:crgId',
        name: 'crgHistoryView',
        component: () => import('@views/accounting/crg/CrgHistoryView.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          permissions: [PermissionMap.CRG_TRANSFERT.VIEW, PermissionMap.CRG_CONSULTATION.VIEW],
        },
      },
      {
        path: 'accounting/bankReconciliation',
        name: 'accountingComptaRapprochementList',
        component: () => import('@views/accounting/bankreconciliation/ComptaRapprochementList.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          permissions: [PermissionMap.BANKING_RECONCILIATION.VIEW],
        },
      },
      {
        path: 'accounting/bankReconciliation/:rapprochementId',
        name: 'accountingComptaRapprochement',
        component: () => import('@views/accounting/bankreconciliation/ComptaRapprochement.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          permissions: [PermissionMap.BANKING_RECONCILIATION.MANAGE],
        },
      },
      {
        path: 'accounting/bankReconciliation/details/:rapprochementId',
        name: 'accountingComptaRapprochementDetails',
        component: () => import('@views/accounting/bankreconciliation/ComptaRapprochementDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          permissions: [PermissionMap.BANKING_RECONCILIATION.VIEW],
        },
      },
      {
        path: 'accounting/recapitulatiffacturation',
        name: 'accountingRecapitulatifFacturation',
        component: () => import('@views/accounting/RecapitulatifFacturation.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          //permissions: [PermissionMap.RECAPITULATIF_FACTURATION.VIEW],
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'accounting/ownercancel',
        name: 'accountingOwnerCancel',
        component: () => import('@views/accounting/ownercancel/OwnerCancel.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'accounting/ownercancel/:reservationId',
        name: 'accountingOwnerCancelDetails',
        component: () => import('@views/accounting/ownercancel/OwnerCancelDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'accounting',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'onboarding/:onboardingId',
        name: 'onboardingDetails',
        component: () => import('@views/onboarding/OnboardingDetails.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'commercial',
        },
      },
      {
        path: 'contact',
        name: 'contact',
        component: require('@views/mescontacts/MesContacts.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'contact',
        },
      },
      {
        path: 'operationnel/accommodation/planning',
        name: 'accommodationPlanning',
        component: require('@views/operationnel/accommodationplanning/AccommodationPlanning.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'accommodationPlanning',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'suivimissions',
        name: 'suiviMissions',
        component: require('@views/operationnel/missionsuivi/MissionSuivi.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'suiviMissions',
        },
      },
      {
        path: 'operationnel/mission/planning/intervenants',
        name: 'missionPlanningParticipant',
        component: require('@views/operationnel/mission/MissionPlanningParticipant.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'planningEmployees',
        },
      },
      {
        path: 'waiting-orders',
        name: 'waiting-orders',
        component: require('@views/operationnel/waitingorders/MissionWaitingOrders.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'waiting-orders',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'orders/create/:accommodationId',
        name: 'ordersCreate',
        component: require('@views/operationnel/waitingorders/MissionOrderCreate.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'waiting-orders',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'mission-orders',
        name: 'mission-orders',
        component: require('@views/operationnel/missionorders/MissionOrderList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'mission-orders',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'mission-dashboard',
        name: 'mission-dashboard',
        component: require('@views/operationnel/missiondashboard/MissionDashboard.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'mission-dashboard',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'mission-order/:missionOrderId',
        name: 'mission-order-detail',
        component: require('@views/operationnel/missionorders/MissionOrderPage.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'mission-orders',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'maintenances/owner',
        name: 'maintenancesOwner',
        component: require('@views/maintenance/MaintenanceOwnerPage.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'maintenancesOwner',
          accessTo: ['OWNER'],
        },
      },
      {
        path: 'prestataires',
        name: 'prestataires',
        component: require('@views/prestataires/PrestataireList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'prestataires',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'prestataires/create',
        name: 'prestatairesCreate',
        component: require('@views/prestataires/PrestataireCreate.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'prestataires',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'prestataires/:prestataireId',
        name: 'prestatairePage',
        component: require('@views/prestataires/PrestatairePage.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'prestataires',
          accessTo: ['GAS', 'GAV'],
        },
      },
      {
        path: 'partenaires',
        name: 'partenaires',
        component: require('@views/partenaires/PartenaireList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'partenaires',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'partenaire/create',
        name: 'partenaireCreate',
        component: require('@views/partenaires/PartenaireCreate.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'partenaires',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'partenaire/:partenaireId/update',
        name: 'partenaireUpdate',
        component: () => import('@views/partenaires/PartenaireUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'partenaires',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'partenaire/:partenaireId',
        name: 'partenairePage',
        component: require('@views/partenaires/PartenairePage.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'partenaires',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'housekeepers',
        name: 'housekeepers',
        component: require('@views/housekeepers/HousekeeperList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'housekeeper',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'housekeeper/create',
        name: 'housekeeperCreate',
        component: require('@views/housekeepers/HousekeeperCreate.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'housekeeper',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'housekeeper/:housekeeperId/update',
        name: 'housekeeperUpdate',
        component: () => import('@views/housekeepers/HousekeeperUpdate.vue'),
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'housekeeper',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'housekeeper/:housekeeperId',
        name: 'housekeeperPage',
        component: require('@views/housekeepers/HousekeeperPage.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'rh',
          subMenu: 'housekeeper',
          accessTo: ['GAS', 'GAV', 'ADMIN'],
        },
      },
      {
        path: 'litiges',
        name: 'litiges',
        component: require('@views/litige/LitigeList.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'litiges',
          accessTo: ['GAS', 'GAV', 'PARTENAIRE'],
        },
      },
      {
        path: 'litige/create',
        name: 'litigeCreate',
        component: require('@views/litige/LitigeCreate.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'litiges',
          accessTo: ['PARTENAIRE'],
        },
      },
      {
        path: 'litige/update/:litigeId',
        name: 'litigeUpdate',
        component: require('@views/litige/LitigeUpdate.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'litiges',
          accessTo: ['GAS', 'GAV', 'PARTENAIRE'],
        },
      },
      {
        path: 'litige/details/:litigeId',
        name: 'litigeDetails',
        component: require('@views/litige/LitigeDetails.vue').default,
        meta: {
          authRequired: true,
          menuSelected: 'operational',
          subMenu: 'litiges',
          accessTo: ['GAS', 'GAV', 'PARTENAIRE', 'OWNER'],
        },
      },
      {
        path: '403',
        name: 'unauthorized',
        component: require('@views/core/_unauthorized.vue').default,
      },
      {
        path: '404',
        name: 'notFound',
        component: require('@views/core/_404.vue').default,
      },
      {
        path: '500',
        name: 'unexpected',
        component: require('@views/core/_500.vue').default,
      },
    ],
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/core/_404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '404',
  },
];
