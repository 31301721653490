export default {
  data() {
    return {
      prestationTypesConst: [
        {label: this.$t('prestation.prestationType_check_in'), value: 'CHECK_IN'},
        {label: this.$t('prestation.prestationType_check_out'), value: 'CHECK_OUT'},
        {label: this.$t('prestation.prestationType_cleaning'), value: 'CLEANING'},
        {label: this.$t('prestation.prestationType_cleaning_before'), value: 'CLEANING_BEFORE'},
        {label: this.$t('prestation.prestationType_cleaning_after'), value: 'CLEANING_AFTER'},
        {label: this.$t('prestation.prestationType_running'), value: 'RUNNING'},
        {label: this.$t('prestation.prestationType_maintenance'), value: 'MAINTENANCE'},
        {label: this.$t('prestation.prestationType_quality_control'), value: 'QUALITY_CONTROL'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    // FIXME : renommer la méthode en displayForPrestationTypesConst
    displayForPrestationTypeConst(value) {
      if (!value) return '';
      let result = this.prestationTypesConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    // liste utilisée à la création et modification d'un partenaire
    // cas où aucun partenaire n'est rattaché au logement -> filtre si réception Autonome
    displayPrestationTypeConstForPartner(receptionTypeCheckin, receptionTypeCheckout) {
      let list = this.prestationTypesConst.filter((el) => el.value !== undefined);
      if (receptionTypeCheckin === 'AUTONOMOUS') {
        list = list.filter((el) => el.value !== 'CHECK_IN');
      }
      if (receptionTypeCheckout === 'AUTONOMOUS') {
        list = list.filter((el) => el.value !== 'CHECK_OUT');
      }
      return list;
    },
    // liste utilisée à la création et modification d'un partenaire
    // cas où un partenaire est déjà rattaché au logement et a des missions attribuées
    displayPrestationTypeConstForPartnerFilterMissionTypes(missionTypes, receptionTypeCheckin, receptionTypeCheckout) {
      let list = this.displayPrestationTypeConstForPartner(receptionTypeCheckin, receptionTypeCheckout);
      if (missionTypes) {
        // Crée une liste ne contenant pas le type de mission
        list = list.filter((el) => !missionTypes.some((missionType) => missionType.prestationType === el.value));
      }
      return list;
    },
  },
};
