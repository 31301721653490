<script>
/* COMPOSANTS */
import AkMultiSelect from '@components/input/AkMultiSelect';
import InputText from 'primevue/inputtext';
import PrestationBadge from '@components/general/PrestationBadge.vue';
import PrestationStatusBadge from '@components/general/PrestationStatusBadge.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import missionOrderService from '@services/missionOrderService';
import companyService from '@services/companyService';

export default {
  components: {
    InputText,
    AkMultiSelect,
    PrestationBadge,
    PrestationStatusBadge,
  },
  mixins: [permissionsMixin, utilsMixin],
  metaInfo() {
    return {
      title: 'mission_order.title',
    };
  },
  data() {
    return {
      list: [],
      loading: false,
      textSearch: '',
      companies: [],
      chosenCompanies: [],
      filter: {
        page: 0,
        limit: 10,
        sort: 'date_created:DESC',
      },
      totalRecords: 0,
      activeTab: 0,
    };
  },
  watch: {
    activeTab: function () {
      this.filter.page = 0;
      this.onPage({page: this.filter.page, rows: this.filter.limit});
    },
  },
  mounted() {
    this.onPage({page: this.filter.page, rows: this.filter.limit});

    let p1 = companyService.companies();
    p1.then((data) => {
      this.companies = data;
    });
  },
  computed: {
    getStatusFromTab() {
      if (this.activeTab === 1) {
        return 'PROPOSED';
      }
      if (this.activeTab === 2) {
        return 'ACCEPTED';
      }
      if (this.activeTab === 3) {
        return 'COMPLETED';
      }
      if (this.activeTab === 4) {
        return 'LITIGATION';
      }
      if (this.activeTab === 5) {
        return 'CANCELED';
      }
      return '';
    },
    isActiveTabCanceled() {
      return this.activeTab === 5;
    },
    first() {
      return (this.filter.page - 1) * this.filter.limit;
    },
  },
  methods: {
    onMouseDown() {
      this.mouseDownStart = Date.now();
    },
    rowClick(event) {
      if (Date.now() - this.mouseDownStart > 300) return;
      this.$router.push({
        name: 'mission-order-detail',
        params: {missionOrderId: event.data.id},
      });
    },
    refresh() {
      this.loading = true;

      this.filter.query = this.textSearch;
      this.filter.companyIds = this.chosenCompanies;
      this.filter.status = this.getStatusFromTab;

      missionOrderService
        .pagination(this.filter)
        .then((data) => {
          this.totalRecords = data.totalItems;
          this.list = data.data;
        })
        .finally(() => (this.loading = false));
    },
    onPage(event) {
      this.filter.page = event.page + 1;
      this.filter.limit = event.rows;
      this.refresh();
    },
    submitForm() {
      this.filter.page = 0;
      this.onPage({page: this.filter.page, rows: this.filter.limit});
    },
    filtersChosen() {
      this.filter.page = 0;
      this.onPage({page: this.filter.page, rows: this.filter.limit});
    },
  },
};
</script>

<template>
  <div class="h-100 d-flex flex-column no-mission-order-list">
    <GaTitleMenu :with-bullet="false">
      <GaItemTitle :active="true" :title="$t('mission_order.title')" />
      <form class="p-input-icon-left ml-auto" @submit.prevent="submitForm">
        <i class="pi pi-search" />
        <InputText :placeholder="$t('search.search')" v-model="textSearch" />
      </form>
      <AkMultiSelect
        v-model="chosenCompanies"
        v-if="this.isGas()"
        :options="companies"
        option-label="name"
        option-value="id"
        label="Filtrer"
        placeholder="Filtrer"
        :displayLabel="false"
        class="filter"
        :blurEvent="filtersChosen" />
    </GaTitleMenu>
    <div class="tab-container mt-2">
      <div class="tab" :class="activeTab === 0 ? 'active' : ''" @click="activeTab = 0">Tous les ordres</div>
      <div class="tab" :class="activeTab === 1 ? 'active' : ''" @click="activeTab = 1">Proposée</div>
      <div class="tab" :class="activeTab === 2 ? 'active' : ''" @click="activeTab = 2">Acceptée</div>
      <div class="tab" :class="activeTab === 3 ? 'active' : ''" @click="activeTab = 3">Terminée</div>
      <div class="tab" :class="activeTab === 4 ? 'active' : ''" @click="activeTab = 4">Litige</div>
      <div class="tab" :class="activeTab === 5 ? 'active' : ''" @click="activeTab = 5">Annulée</div>
    </div>
    <div class="content-list">
      <DataTable
        :always-show-paginator="false"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :first="this.first"
        :value="list"
        lazy
        :loading="loading"
        :totalRecords="totalRecords"
        class="table pointer gd-datatable"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        striped-rows
        @mousedown="onMouseDown"
        @row-click="rowClick($event)"
        @page="onPage($event)">
        <template #empty> Aucun ordre de mission </template>

        <Column header="Numéro réservation">
          <template #body="slotProps">
            <span class="text-break">{{ slotProps.data.reservation?.reservationNum ?? '-' }}</span>
          </template>
        </Column>

        <Column header="Statut des intervenants">
          <template #body="slotProps">
            <PrestationStatusBadge :status="slotProps.data.statusParticipants" />
          </template>
        </Column>

        <Column header="Statut de la mission">
          <template #body="slotProps">
            <PrestationStatusBadge :status="slotProps.data.statusPrestation" />
          </template>
        </Column>

        <Column header="Nb d'intervenants" class="text-center">
          <template #body="slotProps">
            {{ slotProps.data.participantNumber }}
          </template>
        </Column>

        <Column header="Date ordre">
          <template #body="slotProps">
            {{ new Date(slotProps.data.dateCreated).toLocaleDateString() }}
          </template>
        </Column>

        <Column header="GAV" v-if="this.isGas()">
          <template #body="slotProps">
            {{
              companies.length > 0
                ? companies.filter((company) => company.id === slotProps.data.accommodation.companyId)[0].name
                : ''
            }}
          </template>
        </Column>

        <Column header="Nom du logement">
          <template #body="slotProps">
            {{ slotProps.data.accommodation.name }}
          </template>
        </Column>

        <Column header="Date début prestation">
          <template #body="slotProps">
            {{ slotProps.data.dateStart ? new Date(slotProps.data.dateStart).toLocaleDateString() : '-' }}
          </template>
        </Column>

        <Column header="Date check in">
          <template #body="slotProps">
            {{
              slotProps.data.reservation?.dateCheckin
                ? new Date(slotProps.data.reservation?.dateCheckin).toLocaleDateString()
                : '-'
            }}
          </template>
        </Column>

        <Column header="Date check out">
          <template #body="slotProps">
            {{
              slotProps.data.reservation?.dateCheckout
                ? new Date(slotProps.data.reservation?.dateCheckout).toLocaleDateString()
                : '-'
            }}
          </template>
        </Column>

        <Column header="Adresse du logement">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.accommodation.address }}
            </div>
            <div>
              {{ slotProps.data.accommodation.postalCode + ' ' + slotProps.data.accommodation.city }}
            </div>
          </template>
        </Column>

        <Column header="Date d'annulation" v-if="this.isActiveTabCanceled">
          <template #body="slotProps">
            {{ this.displayDateTime(slotProps.data.cancellationDate) }}
          </template>
        </Column>

        <Column header="Personne qui a annulé la mission" v-if="this.isActiveTabCanceled">
          <template #body="slotProps">
            {{ slotProps.data.cancellationUsername }}
          </template>
        </Column>

        <Column header="Type">
          <template #body="slotProps">
            <PrestationBadge :type="slotProps.data.type" :key="slotProps.data.type" />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-mission-order-list {
  .calendar {
    width: 200px;
  }

  .p-input-icon-left {
    display: flex;
    align-items: center;
  }

  .p-inputtext {
    width: 400px;
  }

  .pi-search {
    left: 14px !important;
  }

  .status-badge {
    width: fit-content;
    padding: 2px 8px;
    background-color: #ecfdf3;
    color: #027a48;
    border-radius: 16px;
  }

  .date-badge {
    border-radius: 32px;
    padding: 5px 8px;
    font: 600 12px/18px 'Nunito Sans';
    color: white;
  }

  .task {
    padding: 8px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    > span {
      font: 400 14px/20px 'Nunito Sans';
      color: white;
    }
  }

  .gd-btn-filter {
    margin: 0;
  }

  .btn-create {
    height: 44px;
    background-color: #0b3575;
    color: #ffffff;
  }

  .gap-16 {
    gap: 16px;
  }

  .btn-filter {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  .btn-create {
    height: 44px;
    background-color: #0b3575;
    color: #ffffff;
  }

  .filter {
    max-width: 200px;
  }

  .tab-container {
    display: flex;
    flex-direction: row;
    height: 32px;
    gap: 24px;
  }

  .tab {
    width: fit-content;
    cursor: pointer;
    color: #667085;
    height: 32px;
    box-sizing: border-box;

    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 600;
  }

  .tab.active {
    color: #092858;
    border-bottom: solid 2px #092858;
  }

  .filter {
    margin-bottom: 0;
    > .mb-label {
      margin-bottom: 0;
    }
  }
}
.title-container {
  display: flex;
  flex-direction: row;
  gap: 26px;
  align-items: center;
}
</style>
